<div class="landing-pro__main__footer__links">
  <img (click)="navigateToNxhCom()" src="/assets/theming-default/nxh-logo.svg" />

  <ng-container *ngIf="location === 'product-selector'">
    <ng-container [ngSwitch]="preferredLanguage$ | async">
      <a *ngSwitchCase="'nl'" target="_blank" href="https://nexuzhealth.com/nl/privacyverklaring/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'en'" target="_blank" href="https://nexuzhealth.com/en/privacy-statement/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'de'" target="_blank" href="https://nexuzhealth.com/en/privacy-statement/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'fr'" target="_blank" href="https://nexuzhealth.com/fr/declaration-de-confidentialite/">{{
        '_legal.privacy' | i18next
      }}</a>
    </ng-container>
    <ng-container [ngSwitch]="preferredLanguage$ | async"></ng-container>
  </ng-container>

  <ng-container *ngIf="location === 'app'">
    <a target="_blank" routerLink="/legal/terms-of-use">{{ '_legal.terms-of-use' | i18next }}</a>
    <ng-container [ngSwitch]="preferredLanguage$ | async">
      <a *ngSwitchCase="'nl'" target="_blank" href="https://nexuzhealth.com/nl/privacyverklaring/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'en'" target="_blank" href="https://nexuzhealth.com/en/privacy-statement/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'de'" target="_blank" href="https://nexuzhealth.com/en/privacy-statement/">{{
        '_legal.privacy' | i18next
      }}</a>
      <a *ngSwitchCase="'fr'" target="_blank" href="https://nexuzhealth.com/fr/declaration-de-confidentialite/">{{
        '_legal.privacy' | i18next
      }}</a>
    </ng-container>
    <a target="_blank" routerLink="/legal/cookies">{{ '_legal.cookie' | i18next }}</a>
  </ng-container>

  <div ngbDropdown class="lang-switch hide-default-down-icon">
    <div ngbDropdownMenu>
      <button class="btn" ngbDropdownItem (click)="updatePreferredLanguage('nl')">
        <div>{{ 'language-nl' | i18next }}</div>
      </button>
      <button class="btn" ngbDropdownItem (click)="updatePreferredLanguage('en')">
        <div>{{ 'language-en' | i18next }}</div>
      </button>
    </div>
    <div ngbDropdownToggle>
      <fa-icon icon="globe"></fa-icon>
      <span class="current-lang">{{ preferredLanguage$ | async }}</span>
      <fa-icon icon="chevron-down" class="chevron"></fa-icon>
    </div>
  </div>
</div>
