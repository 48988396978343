import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventChannelService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { EMPTY, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, finalize, takeUntil, timeout } from 'rxjs/operators';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';

@Component({
  templateUrl: './logout-callback-page.component.html',
  styleUrls: ['./logout-callback-page.component.scss'],
})
export class LogoutCallbackPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private settings: SettingsService,
    private eventChannel: EventChannelService,
    public themeQuery: ThemeQuery
  ) {}

  ngOnInit() {
    const apps = this.settings.custom.applications || [];
    const applications$ = apps.map((application) =>
      of(application).pipe(
        concatMap((app) =>
          this.startLogout(app.name, app.url).pipe(
            timeout(this.settings.custom.logoutTimeout || 30000), // timeout after 30 seconds
            catchError(() => {
              return EMPTY;
            }),
            finalize(() => this.removeIframe(`iframe-${app.name}`))
          )
        )
      )
    );

    forkJoin(...applications$)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          this.router.navigate(['/']);
        },
      });

    this.eventChannel.sendLogout();
    this.logOutEhealth();
  }

  private removeIframe(id) {
    const el = window.document.getElementById(id);
    if (el) {
      el.remove();
    }
  }

  private startLogout(app, url): Observable<boolean> {
    return from(
      new Promise<boolean>((res) => {
        const iframe = window.document.createElement('iframe');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.setAttribute('id', `iframe-${app}`);
        iframe.style.display = 'none';

        const iframeEventHandler = function (e: MessageEvent) {
          if (e.origin !== url) return;
          if (!e.data || !e.data.app) return;
          const eventSource = e.source;
          if (eventSource) {
            (<any>eventSource).close();
          }
          res(true);
          window.removeEventListener('message', iframeEventHandler, false);
        };
        window.addEventListener('message', iframeEventHandler, false);

        window.document.body.appendChild(iframe);
        iframe.setAttribute('src', url + '/single-logout');
      })
    );
  }

  private logOutEhealth() {
    const endpoint = this.settings.logOutEhealthEndpoint;
    if (endpoint) {
      const url = new URL(endpoint);
      const win = window.open(url.toString(), '_blank');
      win.focus();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
