import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { EventChannelService, SessionService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, defer, from, iif, merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

export interface AuthMessage {
  type: 'info' | 'error';
  message: string;
}

const key_target_app = 'nh.auth.login_target-app';
const key_last_message = 'nh.auth.login_last-message';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
  private errorSubject$ = new ReplaySubject<Error>(1);
  private refreshState$ = new Subject<void>();

  readonly isLoading$ = this.isLoadingSubject$.asObservable();
  private isAuthenticatedTrigger$: Observable<boolean>;
  readonly isAuthenticated$: Observable<boolean>;
  readonly error$ = this.errorSubject$.asObservable();

  private auth0Client: Auth0Client;

  set targetApp(targetApp: string) {
    sessionStorage.setItem(key_target_app, targetApp);
  }

  get targetApp(): string | null {
    return sessionStorage.getItem(key_target_app);
  }

  set lastMessage(message: AuthMessage | null) {
    if (message === null) {
      sessionStorage.removeItem(key_last_message);
    } else {
      sessionStorage.setItem(key_last_message, JSON.stringify(message));
    }
  }

  get lastMessage(): AuthMessage | null {
    const json = sessionStorage.getItem(key_last_message);
    return json ? JSON.parse(json) : null;
  }

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private settings: SettingsService,
    private eventChannel: EventChannelService,
    private idle: Idle,
    private location: Location
  ) {
    this.auth0Client = new Auth0Client({
      domain: this.settings.auth0config.domain,
      client_id: this.settings.auth0config.clientId,
      redirect_uri: `${window.location.origin}`,
      audience: this.settings.auth0config.audience,
      useRefreshTokens: true,
      cacheLocation: 'memory',
    });

    this.isAuthenticatedTrigger$ = this.isLoading$.pipe(
      filter((loading) => !loading),
      distinctUntilChanged(),
      switchMap(() =>
        // To track the value of isAuthenticated over time, we need to merge:
        //  - the current value
        //  - the value whenever refreshState$ emits
        merge(
          defer(() => this.auth0Client.isAuthenticated()),
          this.refreshState$.pipe(mergeMap(() => this.auth0Client.isAuthenticated()))
        )
      )
    );

    this.isAuthenticated$ = this.isAuthenticatedTrigger$.pipe(distinctUntilChanged());

    const checkSessionOrCallback$ = (isCallback: boolean) =>
      iif(
        () => isCallback,
        this.handleRedirectCallback(),
        defer(() => {
          return this.auth0Client.checkSession();
        })
      );

    const queryParamMap = this.router.parseUrl(window.location.pathname + window.location.search).queryParamMap;

    if (queryParamMap.has('logout')) {
      const targetApp = queryParamMap.get('logout');
      if (targetApp) this.targetApp = targetApp;
      this.logout(true);
    } else {
      this.shouldHandleCallback()
        .pipe(
          switchMap((isCallback) =>
            checkSessionOrCallback$(isCallback).pipe(
              catchError((e) => {
                this.errorSubject$.next(e);
                return of(undefined);
              })
            )
          ),
          switchMap(() => this.auth0Client.isAuthenticated()),
          switchMap((authenticated) => (authenticated ? this.sessionService.retrieveUserInformation() : of(undefined))),
          finalize(() => {
            this.isLoadingSubject$.next(false);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (user) => {
            this.setupListeners();
            if (user) {
              this.startIdleTimeout();
            }
          },
        });
    }
  }

  private setupListeners() {
    this.eventChannel.logout$.subscribe(() => {
      this.sessionService.clear();
      this.refreshState$.next();
      this.router.navigate(['/'], {
        queryParams: {
          reason: 'session_expired',
        },
      });
    });

    this.idle.onTimeout.subscribe(() => {
      this.logout(true);
    });
  }

  private startIdleTimeout() {
    if (this.settings.idleTimeout.enabled !== true) {
      return;
    }
    this.idle.setIdle(this.settings.idleTimeout.idleAfter);
    this.idle.setTimeout(this.settings.idleTimeout.timeoutAfter);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.watch();
  }

  private shouldHandleCallback(): Observable<boolean> {
    return of(this.location.path()).pipe(
      map((search) => {
        return (search.includes('code=') || search.includes('error=')) && search.includes('state=');
      })
    );
  }

  private handleRedirectCallback(): Observable<RedirectLoginResult> {
    return defer(() => this.auth0Client.handleRedirectCallback()).pipe(
      tap((result) => {
        const target = result?.appState?.target ?? '/';
        this.router.navigate([target]);
      })
    );
  }

  login(redirectPath: string = '/', connection: string) {
    from(
      this.auth0Client.loginWithRedirect({
        appState: { target: redirectPath },
        connection,
      })
    ).subscribe();
  }

  logout(doSsoLogout: boolean) {
    this.doLogout(doSsoLogout);
  }

  private doLogout(doSsoLogout: boolean) {
    this.sessionService.clear();
    this.auth0Client.logout({
      returnTo: doSsoLogout ? `${window.location.origin}/logout-callback` : `${window.location.origin}`,
    });
  }

  getTokenSilently$(): Observable<string> {
    return of(this.auth0Client).pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently())),
      tap(() => this.refreshState$.next()),
      catchError((error) => {
        this.errorSubject$.next(error);
        this.refreshState$.next();
        return throwError(error);
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.isLoadingSubject$.complete();
    this.errorSubject$.complete();
    this.refreshState$.complete();
  }
}
