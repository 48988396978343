import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthQuery, SessionService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { Tenant, User, UserContext } from '@nexuzhealth/shared/domain';
import { filterNullOrUndefined } from '@nexuzhealth/shared/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { sortBy } from 'lodash-es';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'nxh-context-selector',
  templateUrl: './context-selector.component.html',
  styleUrls: ['./context-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextSelectorComponent implements OnInit {
  tenantSearchControl: UntypedFormControl;

  tenantAlertTitle$ = new Subject<string>();
  roleAlertTitle$ = new Subject<string>();
  selectedTenant$ = new Subject<TenantWithContexts>();
  user$: Observable<User>;
  tenants$: Observable<TenantWithContexts[]>;
  searchedTenants$: Observable<TenantWithContexts[]>;
  loadingTenants$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authQuery: AuthQuery,
    private router: Router,
    public auth: AuthService,
    private session: SessionService
  ) {}

  ngOnInit() {
    if (!this.auth.targetApp) {
      this.router.navigate(['/', 'product-selector']);
    }

    this.user$ = this.authQuery.selectUser();
    this.tenantSearchControl = new UntypedFormControl('');
    this.tenants$ = this.initTenants();

    this.searchedTenants$ = this.tenantSearchControl.valueChanges.pipe(
      startWith(''),
      tap(() => {
        this.loadingTenants$.next(true);
      }),
      withLatestFrom(this.tenants$),
      map(([searchTerm, tenants]) => {
        const searchedTenants =
          searchTerm && searchTerm.length >= 2
            ? tenants.filter((tenant) => tenant.description.toLowerCase().startsWith(searchTerm.toLowerCase()))
            : tenants;

        return sortBy(searchedTenants, 'description');
      }),
      tap(() => {
        this.loadingTenants$.next(false);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getDisplayName(user: User) {
    return `${user.displayName.prefix} ${user.displayName.family} ${user.displayName.given}`.trim();
  }

  selectTenant(tenant: TenantWithContexts) {
    this.roleAlertTitle$.next('');

    if (tenant.contexts.length === 1) {
      this.signIn(tenant.contexts[0].name);
      return;
    }
    if (tenant.contexts.length === 0) {
      this.roleAlertTitle$.next('auth:_role-selector.no-role');
    }
    this.selectedTenant$.next(tenant);
  }

  signIn(contextName: string) {
    this.session.useUserContextName(contextName);
    if (this.auth.targetApp) {
      window.location.assign(`${this.auth.targetApp}/login?userContextName=${encodeURIComponent(contextName)}`);
    }
  }

  backButtonClick() {
    this.selectedTenant$.next(null);
  }

  private initTenants(): Observable<TenantWithContexts[]> {
    return this.authQuery.selectLoading().pipe(
      filter((loading) => loading === false),
      switchMap(() => this.authQuery.selectUserContexts()),
      filterNullOrUndefined,
      filter(() => {
        const userContext = this.authQuery.getUserContext();
        if (userContext) {
          this.signIn(userContext.name);
          return false;
        }
        return true;
      }),
      map((contexts) => contexts.filter((context) => context.tenant)),
      map((contexts) =>
        contexts.reduce<TenantWithContexts[]>((tenants, context) => {
          const index = tenants.findIndex((t) => t.name === context.tenant.name);
          if (index === -1) {
            tenants.push({
              ...context.tenant,
              contexts: context.rolesActive?.length > 0 ? [context] : [],
            });
          } else if (context.rolesActive?.length > 0) {
            tenants[index].contexts.push(context);
          }
          return tenants;
        }, [])
      ),
      tap((tenants: TenantWithContexts[]) => {
        if (tenants.length === 1) {
          this.selectTenant(tenants[0]);
        }
        if (tenants.length === 0) {
          this.tenantAlertTitle$.next('auth:_tenant-selector.no-organisation');
        }
        this.selectedTenant$.next(null);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}

interface TenantWithContexts extends Tenant {
  contexts: UserContext[];
}
