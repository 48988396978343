<div class="cannot-log-in">
  {{ 'auth:cannot-log-in' | i18next }}
</div>
<div class="help-center">
  <div>{{ 'auth:contact-help-center-via' | i18next }}&nbsp;</div>
  <a
    class="btn btn-link btn--small btn--no-padding no-capitalization"
    [href]="'mailto:' + (themeQuery.supportEmail$ | async)"
  >
    {{ themeQuery.supportEmail$ | async }}
  </a>
  <div class="no-capitalization">&nbsp;{{ 'or' | i18next }}&nbsp;</div>
  <a class="btn btn-link btn--small btn--no-padding" [href]="'tel:' + (themeQuery.supportPhone$ | async)">
    {{ themeQuery.supportPhone$ | async | formatPhoneNumber }}
  </a>
</div>
