import { Component } from '@angular/core';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';

@Component({
  selector: 'nxh-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class ContactSupportComponent {
  constructor(public themeQuery: ThemeQuery) {}
}
