import { Directive, ElementRef, Input, Optional, Self } from '@angular/core';
import { fromEvent } from 'rxjs';
import { FormHelperDirective } from './form-helper.directive';

@Directive({ selector: '[nxhSkipDirtyCheck]' })
export class SkipDirtyCheckDirective {
  @Input('nxhSkipDirtyCheck') skip = false;

  constructor(@Self() el: ElementRef, @Optional() private fhd?: FormHelperDirective) {
    if (fhd) {
      fromEvent(el.nativeElement, 'click', { capture: false }).subscribe(() => {
        if (this.skip) this.fhd.setDirty(false);
      });
    }
  }
}
