import { IconLoaderModule } from '@nexuzhealth/shared/ui-toolkit/icon-loader';
import { BackButtonModule } from '@nexuzhealth/shared/ui-toolkit/back-button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import {
  UserPreferencesQuery,
  UserPreferencesService,
} from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { FEATURE_FLAG_DATASOURCE, FEATURE_FLAG_QUERY } from '@nexuzhealth/shared/settings/feature-flags/ui';
import { SharedToolkitFeatureShellModule } from '@nexuzhealth/shared/toolkit/feature-shell';
import { LoginThemeResolver, SharedToolkitFeatureThemingModule } from '@nexuzhealth/shared/toolkit/feature-theming';
import { AlertModule } from '@nexuzhealth/shared/ui-toolkit/alert';
import { TinyLoaderModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/tiny-loader';
import { PhoneNumberModule } from '@nexuzhealth/shared/ui-toolkit/phone-number';
import {
  HttpErrorInterceptor,
  I18NEXT_NAMESPACES,
  I18nextConfigService,
  NamespacesConfig,
  preloadAll,
  SharedUtilI18nextArrayByKeyPipeModule,
  SharedUtilI18nModule,
} from '@nexuzhealth/shared/util';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FocusModule } from '@nexuzhealth/shared/ui-toolkit/focus';
import { InputModule } from '@nexuzhealth/shared/ui-toolkit/input';
import { PartialDatePipeModule } from '@nexuzhealth/shared/ui-toolkit/l10n/partial-date';
import { ToastrModule } from 'ngx-toastr';
import { provideNgxMask } from 'ngx-mask';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { MoaprSessionService, SessionService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { BLOB_SERVICE_TOKEN } from '@nexuzhealth/shared/tech/util';
import { BlobService } from '@nexuzhealth/shared/tech/data-access-blob';
import { AppShellComponent } from './app-shell/app-shell.component';
import { AppComponent } from './app.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { ContextSelectorComponent } from './components/home-page/context-selector/context-selector.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { LoginComponent } from './components/home-page/login/login.component';
import { LogoutCallbackPageComponent } from './components/logout-callback-page/logout-callback-page.component';
import { ContactSupportProComponent } from './components/pro/contact-support-pro/contact-support-pro.component';
import { LandingPageProComponent } from './components/pro/landing-page-pro/landing-page-pro.component';
import { AuthInterceptor } from './shared/auth.interceptor';

const routes: Routes = [
  {
    path: '',
    component: AppShellComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomePageComponent,
      },
      {
        path: 'logout-callback',
        pathMatch: 'full',
        component: LogoutCallbackPageComponent,
      },
      {
        path: 'product-selector',
        pathMatch: 'full',
        component: LandingPageProComponent,
      },
      {
        path: 'legal',
        loadChildren: () => import('@nexuzhealth/login/feature-legal').then((m) => m.LoginFeatureLegalModule),
      },
    ],
    resolve: { theme: LoginThemeResolver },
  },
];

@NgModule({
  declarations: [
    AppComponent,
    AppShellComponent,
    HomePageComponent,
    ContextSelectorComponent,
    LoginComponent,
    LogoutCallbackPageComponent,
    ContactSupportComponent,
    LandingPageProComponent,
    ContactSupportProComponent,
  ],
  imports: [
    IconLoaderModule,
    BackButtonModule,
    TinyLoaderModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
    HttpClientModule,
    SharedUtilI18nModule,
    NgIdleModule.forRoot(),
    SharedToolkitFeatureShellModule,
    NgbDropdownModule,
    AlertModule,
    SharedToolkitFeatureThemingModule,
    ScrollingModule,
    ReactiveFormsModule,
    InputModule,
    PhoneNumberModule,
    SharedUtilI18nextArrayByKeyPipeModule,
    SharedTechFeatureE2eModule,
    FocusModule,
    PartialDatePipeModule.forRoot(),
    ToastrModule.forRoot(),
    IconsModule.forRoot(),
    ButtonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadAll,
      deps: [SettingsService, UserPreferencesService, I18nextConfigService],
    },
    {
      provide: I18NEXT_NAMESPACES,
      useValue: {
        ns: ['common', 'auth'],
        defaultNS: 'auth',
        fallbackNS: 'common',
      } as NamespacesConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (userPreferencesQuery: UserPreferencesQuery) => {
        return userPreferencesQuery.getPreferredLanguage();
      },
      deps: [UserPreferencesQuery], //some service handling global settings
    },
    {
      provide: FEATURE_FLAG_QUERY,
      useClass: FeatureFlagQuery,
    },
    {
      provide: FEATURE_FLAG_DATASOURCE,
      useClass: FeatureFlagService,
    },

    { provide: SessionService, useClass: MoaprSessionService },
    { provide: BLOB_SERVICE_TOKEN, useClass: BlobService },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
