import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesService } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { Environment, EnvironmentsService } from '@nexuzhealth/shared/util';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'nxh-landing-page-pro',
  templateUrl: './landing-page-pro.component.html',
  styleUrls: ['./landing-page-pro.component.scss'],
})
export class LandingPageProComponent implements OnInit {
  envPill: string;
  whatIsNxhConsultUrl$: Observable<string>;
  whatIsNxhProUrl$: Observable<string>;

  gpUrl: string;
  hnUrl: string;
  orgUrl: string;
  consultUrl: string;

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private environmentsService: EnvironmentsService,
    private settingsService: SettingsService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Product selector');
    this.whatIsNxhConsultUrl$ = this.userPreferencesService.loadPreferredLanguage().pipe(
      map((language) => {
        const languageMap = new Map<string, string>([
          ['nl', 'https://nexuzhealth.com/nl/consult/'],
          ['fr', 'https://nexuzhealth.com/fr/consult/'],
          ['en', 'https://nexuzhealth.com/en/consult/'],
          ['de', 'https://nexuzhealth.com/en/consult/'],
        ]);
        return languageMap.get(language) || 'https://nexuzhealth.com/nl/consult/';
      })
    );
    this.whatIsNxhProUrl$ = this.userPreferencesService.loadPreferredLanguage().pipe(
      map((language) => {
        const languageMap = new Map<string, string>([
          ['nl', 'https://nexuzhealth.com/nl/nexuzhealth-pro/'],
          ['fr', 'https://nexuzhealth.com/fr/nexuzhealth-pro/'],
          ['en', 'https://nexuzhealth.com/en/nexuzhealth-pro/'],
          ['de', 'https://nexuzhealth.com/en/nexuzhealth-pro/'],
        ]);
        return languageMap.get(language) || 'https://nexuzhealth.com/en/nexuzhealth-pro/';
      })
    );
    if (this.environmentsService.environment !== Environment.PRD) {
      this.envPill = this.environmentsService.environmentFull;
    }
    this.consultUrl = this.settingsService.custom.nxhProUrl;
    this.gpUrl = this.lookupUrlFromSettings('gp');
    this.hnUrl = this.lookupUrlFromSettings('hn');
    this.orgUrl = this.lookupUrlFromSettings('org');
  }

  lookupUrlFromSettings(applicationName): string {
    const result = this.settingsService.custom.applications?.find((val) => val.name === applicationName);
    return result?.url ?? `https://${applicationName}.nexuzhealth.pro`;
  }

  logout() {
    this.auth.logout(false);
  }

  navigateToGP() {
    const gp = this.settingsService.custom.applications?.find((val) => val.name === 'gp');
    window.open(gp?.url ?? 'https://gp.nexuzhealth.pro', '_self');
  }

  navigateToORG() {
    const gp = this.settingsService.custom.applications?.find((val) => val.name === 'org');
    window.open(gp?.url ?? 'https://org.nexuzhealth.pro', '_self');
  }

  navigateToHN() {
    const gp = this.settingsService.custom.applications?.find((val) => val.name === 'hn');
    window.open(gp?.url ?? 'https://hn.nexuzhealth.pro', '_self');
  }

  navigateToMyNexuzPro() {
    window.open(this.settingsService.custom.nxhProUrl, '_self');
  }
}
