<div class="login-container">
  <div class="login-container__header">
    <h1>{{ 'auth:_login.welcome-title' | i18next }}</h1>
    <h3 class="text-muted">{{ 'auth:_login.welcome-subtitle' | i18next }}</h3>
  </div>
  <div class="login-container__body">
    <nxh-alert
      *ngIf="message"
      [alertTitle]="getMessageTitle(message.message) | i18next"
      [description]="getMessageBody(message.message) | i18next"
      [type]="message.type === 'info' ? 'info' : 'danger'"
    ></nxh-alert>

    <ng-container *ngFor="let btn of loginBtns; let i = index">
      <button
        type="button"
        class="btn login-btn"
        [class.login-btn--not-supported]="!btn.supported"
        (click)="login(btn.type)"
        [attr.data-cy]="btn.attr"
      >
        <div class="login-btn__logo-container">
          <img *ngFor="let logoUrl of btn.logoUrls" [src]="logoUrl" />
        </div>
        <div class="login-btn__content-container">{{ btn.titleTranslationKey | i18next }}</div>
      </button>
    </ng-container>
  </div>
</div>
