<div class="logout-callback-container">
  <main class="logout-callback-container__main">
    <div class="logout-callback-container__main__header">
      <img class="logout-callback__logo-container--logo" [src]="themeQuery.logoUrl$ | async" />
    </div>
    <div class="logout-callback-container__main__body">
      <div class="loader-container">
        <div class="loader-container__icon">
          <nxh-icon-loader></nxh-icon-loader>
        </div>
      </div>
    </div>
    <footer class="logout-callback-container__main__footer">
      <nxh-contact-support></nxh-contact-support>
    </footer>
  </main>
  <div
    class="logout-callback-container__extra"
    [ngStyle]="{ 'background-image': 'url(' + (themeQuery.backgroundImageUrl$ | async) + ')' }"
  >
    <div class="logout-callback-container__extra__footer">
      <div class="logout-callback-container__extra__footer__text">Powered by</div>
      <img src="/assets/theming-default/nxh-logo.svg" />
    </div>
  </div>
</div>
