import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@nexuzhealth/shared/authentication/data-access-auth';
import { Error, ErrorKind } from '@nexuzhealth/shared/domain';
import { Environment, EnvironmentsService, filterNullOrUndefined } from '@nexuzhealth/shared/util';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';
import { AuthMessage, AuthService } from '../../shared/auth.service';

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  error$ = new BehaviorSubject<Error>(null);
  message$ = new BehaviorSubject<AuthMessage>(null);
  envPill: string;

  constructor(
    public auth: AuthService,
    private authQuery: AuthQuery,
    private route: ActivatedRoute,
    private titleService: Title,
    private environmentsService: EnvironmentsService,
    public themeQuery: ThemeQuery
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Login');

    if (this.route.snapshot.queryParamMap.has('app')) {
      this.auth.targetApp = this.route.snapshot.queryParamMap.get('app');
    }

    this.authQuery.error$.pipe(filterNullOrUndefined, takeUntil(this.destroy$)).subscribe((error: Error) => {
      const message = {
        type: 'error',
        message: error.kind,
      } as AuthMessage;
      if (error.kind === ErrorKind.UNAUTHENTICATED) {
        this.auth.lastMessage = message;
        this.auth.logout(false);
        return;
      }
      this.error$.next(error);
      this.message$.next(message);
    });

    this.route.queryParamMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params.get('reason')),
        filter((reason) => !!reason)
      )
      .subscribe((reason) => {
        if (reason === 'session_expired') {
          this.message$.next({
            type: 'info',
            message: reason,
          });
        }
      });

    if (this.auth.lastMessage) {
      this.message$.next(this.auth.lastMessage);
      this.auth.lastMessage = null;
    }

    if (this.environmentsService.environment !== Environment.PRD) {
      this.envPill = this.environmentsService.environmentFull;
    }
  }

  logout() {
    this.auth.logout(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
