import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthQuery } from '@nexuzhealth/shared/authentication/data-access-auth';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private timeZone: string;

  constructor(
    private auth: AuthService,
    private settings: SettingsService,
    private authQuery: AuthQuery,
    private userPrefQuery: UserPreferencesQuery
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private get authConfig() {
    return this.settings.authConfig;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const regex = /^api(:(\d{4}))?\/(.*)$/;
    const matches = request.url.match(regex);
    if (matches === null) {
      // Not an API call
      return next.handle(request);
    }

    const port = matches[2];
    const uri = matches[3];
    let url = this.authConfig.endpoint;
    if (port || this.authConfig.port) {
      url += ':' + (port || this.authConfig.port);
    }
    request = request.clone({ url: `${url}/${uri}` });
    if (this.isPublic(uri)) {
      return next.handle(this.setHeaders(request));
    }

    return this.auth.getTokenSilently$().pipe(
      mergeMap((token) => {
        const userContextName = this.authQuery.getUserContextName();
        return next.handle(this.setHeaders(request, token, userContextName));
      }),
      catchError((err) => throwError(err)) // TODO: force logout?
    );
  }

  private isPublic(uri: string) {
    return this.authConfig.publicRoutes.includes(uri);
  }

  private setHeaders(request: HttpRequest<any>, token?: string, userContextName?: string) {
    let headers = request.headers
      .set('x-client-id', this.authConfig.clientId)
      .set('x-client-version', this.authConfig.clientVersion)
      .set('x-user-language', this.userPrefQuery.getPreferredLanguage());

    if (this.timeZone) {
      headers = headers.set('x-client-timezone', this.timeZone);
    }
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (userContextName) {
      headers = headers.set('x-user-context-name', userContextName);
    }
    return request.clone({ headers });
  }
}
