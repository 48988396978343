<div class="home-container">
  <main class="home-container__main">
    <div class="home-container__main__header">
      <div class="img-pill-container">
        <img class="home__logo-container--logo" [src]="themeQuery.logoUrl$ | async" />
        <div *ngIf="envPill" class="environment-pill" [ngClass]="'environment-pill--' + envPill">{{ envPill }}</div>
      </div>
    </div>
    <div class="home-container__main__body">
      <ng-container *ngIf="(auth.isLoading$ | async) === false; else loader">
        <nxh-login
          *ngIf="(auth.isAuthenticated$ | async) === false || (error$ | async)"
          [message]="message$ | async"
        ></nxh-login>
        <nxh-context-selector
          class="home-container__main__body__contexts"
          *ngIf="(auth.isAuthenticated$ | async) && !(error$ | async)"
        ></nxh-context-selector>
      </ng-container>
    </div>
    <footer class="home-container__main__footer">
      <div>
        <button class="btn btn-link" type="button" (click)="logout()" *ngIf="auth.isAuthenticated$ | async">
          <fa-icon icon="sign-out"></fa-icon>
          <span>{{ 'auth:log-out' | i18next }}</span>
        </button>
      </div>
      <nxh-contact-support></nxh-contact-support>
    </footer>
    <div class="home-container__main__footer--bottom">
      <nxh-contact-support-pro location="app"></nxh-contact-support-pro>
    </div>
  </main>
  <div
    class="home-container__extra"
    [ngStyle]="{ 'background-image': 'url(' + (themeQuery.backgroundImageUrl$ | async) + ')' }"
  >
    <div class="home-container__extra__footer">
      <div class="home-container__extra__footer__text">Powered by</div>
      <img src="/assets/theming-default/nxh-logo.svg" />
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="loader-container">
    <div class="loader-container__icon">
      <nxh-icon-loader></nxh-icon-loader>
    </div>
  </div>
</ng-template>
