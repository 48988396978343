<div class="landing-pro">
  <main class="landing-pro__main">
    <div class="landing-pro__main__header">
      <div class="img-pill-container">
        <img class="home__logo-container--logo" src="/assets/theming-default/nxh-logo.svg" />
        <div *ngIf="envPill" class="environment-pill" [ngClass]="'environment-pill--' + envPill">{{ envPill }}</div>
      </div>
    </div>

    <div class="landing-pro__main__body">
      <h1 class="no-capitalization">nexuzhealth<sup>pro</sup></h1>
      <p>{{ '_legal._landingpro.welcometext' | i18next }}</p>
      <div class="products-container">
        <div class="products">
          <a [href]="gpUrl" class="product btn">
            <fa-icon icon="user-md"></fa-icon>
            <div>
              <h2 class="product-title">{{ '_legal._landingpro._gp.title' | i18next }}</h2>
              <span class="product-usage">{{ '_legal._landingpro._gp.subtitle' | i18next }}</span>
            </div>
          </a>
          <a [href]="hnUrl" class="product btn">
            <fa-icon icon="user-nurse"></fa-icon>
            <div>
              <h2 class="product-title">{{ '_legal._landingpro._hn.title' | i18next }}</h2>
              <span>{{ '_legal._landingpro._hn.subtitle' | i18next }}</span>
            </div>
          </a>
          <a [href]="orgUrl" class="product btn">
            <fa-icon icon="user-group-crown"></fa-icon>
            <div>
              <h2 class="product-title">{{ '_legal._landingpro._org.title' | i18next }}</h2>
              <span>{{ '_legal._landingpro._org.subtitle' | i18next }}</span>
            </div>
          </a>
          <a target="_blank" [href]="whatIsNxhProUrl$ | async" class="link">{{
            '_legal._landingpro.what-is-nexuzhealth-pro' | i18next
          }}</a>
        </div>
        <hr />
        <div class="products">
          <a [href]="consultUrl" class="product btn">
            <fa-icon icon="eye"></fa-icon>
            <div>
              <h2 class="product-title no-capitalization">{{ '_legal._landingpro._consult.title' | i18next }}</h2>
              <span>{{ '_legal._landingpro._consult.subtitle' | i18next }}</span>
            </div>
          </a>
          <a target="_blank" [href]="whatIsNxhConsultUrl$ | async" class="link">{{
            '_legal._landingpro.what-is-nexuzhealth-consult' | i18next
          }}</a>
        </div>
      </div>
    </div>

    <footer class="landing-pro__main__footer">
      <div>
        <button nxh-button [buttonType]="'link'" (click)="logout()" *ngIf="auth.isAuthenticated$ | async">
          <fa-icon icon="sign-out"></fa-icon>
          <span>{{ 'sign-out' | i18next }}</span>
        </button>
      </div>
      <nxh-contact-support-pro location="product-selector"></nxh-contact-support-pro>
    </footer>
  </main>
  <div class="landing-pro__extra" [ngStyle]="{ 'background-image': 'url(/assets/theming-default/nxh-bg.jpg)' }">
    <div class="landing-pro__extra__footer">
      <div class="landing-pro__extra__footer__text">Powered by</div>
      <img src="/assets/theming-default/nxh-logo.svg" />
    </div>
  </div>
</div>
