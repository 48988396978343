<div class="context-selector" *ngIf="user$ | async as user">
  <div class="context-selector__header">
    <h3>
      {{ 'auth:_login.welcome-title' | i18next }}
      <span>{{ getDisplayName(user) }},</span>
    </h3>
  </div>

  <div class="context-selector__body" *ngIf="(selectedTenant$ | async) === null">
    <h5 class="text-muted" *ngIf="tenants$ | async">{{ 'auth:_login.choose-organisation' | i18next }}</h5>

    <nxh-alert
      *ngIf="tenantAlertTitle$ | async as tenantAlertTitle"
      [alertTitle]="tenantAlertTitle | i18next"
      [description]="'auth:help-center' | i18next"
    ></nxh-alert>

    <div class="context-selector__body__tenant-container" *ngIf="tenants$ | async as tenants">
      <ng-container *ngIf="tenants.length > 3">
        <nxh-input
          type="search"
          [formControl]="tenantSearchControl"
          [nxhFocus]="true"
          nxhDataTest="tenant-search-input"
          [placeholder]="'_login._tenant.search' | i18next"
        >
          <fa-icon icon="search" nxhSuffix></fa-icon>
        </nxh-input>
      </ng-container>

      <ng-container *ngIf="searchedTenants$ | async as searchedTenants">
        <cdk-virtual-scroll-viewport
          itemSize="104"
          minBufferPx="312"
          maxBufferPx="624"
          class="context-selector__body__tenant-container__section"
          *ngIf="(loadingTenants$ | async) === false && searchedTenants.length; else tinyLoader"
        >
          <button
            *cdkVirtualFor="let tenant of searchedTenants"
            class="btn tenant-card"
            type="button"
            (click)="selectTenant(tenant)"
          >
            {{ tenant.description }}
          </button>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </div>

  <div class="context-selector__body" *ngIf="selectedTenant$ | async as selectedTenant">
    <h5 class="text-muted">
      <nxh-back-button (click)="backButtonClick()" *ngIf="(tenants$ | async).length > 1"></nxh-back-button>
      {{ 'auth:_login.select-role' | i18next }}
      <span>{{ selectedTenant.description }}</span>
    </h5>

    <nxh-alert
      *ngIf="roleAlertTitle$ | async as roleAlertTitle"
      [alertTitle]="roleAlertTitle | i18next"
      [description]="'auth:help-center' | i18next"
    ></nxh-alert>

    <div class="context-selector__body__role-section">
      <ng-container *ngFor="let context of selectedTenant.contexts">
        <button class="btn role-card" (click)="signIn(context.name)" type="button">
          <fa-icon [icon]="'user'" class="role-card__icon"></fa-icon>
          <span class="role-card__text text-muted">{{
            context.rolesActive | i18nextArrayByKey : 'translationKey' : ', '
          }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>

<div class="loader-container" *ngIf="!(user$ | async)">
  <div class="loader-container__icon">
    <nxh-icon-loader></nxh-icon-loader>
  </div>
</div>

<ng-template #tinyLoader>
  <nxh-tiny-loader
    class="empty-state-height"
    [loading]="!!(loadingTenants$ | async)"
    [data]="searchedTenants$ | async"
    [emptyStateTemplate]="emptyTemp"
  >
  </nxh-tiny-loader>
</ng-template>

<ng-template #emptyTemp>
  <span class="tiny-loader__empty-search">
    {{ '_login._tenant._search.empty' | i18next }}
  </span>
</ng-template>
