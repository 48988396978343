import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserPreferencesService } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { Observable } from 'rxjs';

@Component({
  selector: 'nxh-contact-support-pro',
  templateUrl: './contact-support-pro.component.html',
  styleUrls: ['./contact-support-pro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSupportProComponent implements OnInit {
  @Input() location?: string;
  preferredLanguage$: Observable<string>;

  constructor(private userPreferencesService: UserPreferencesService) {}

  ngOnInit(): void {
    this.preferredLanguage$ = this.userPreferencesService.loadPreferredLanguage();
  }

  navigateToNxhCom() {
    window.open('https://nexuzhealth.com', '_blank');
  }

  updatePreferredLanguage(language: string) {
    this.userPreferencesService.setPreferredLanguage(language);
    window.location.reload();
  }
}
