import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Theme } from './theme';
import { AbstractThemeResolver } from './abstract-theme.resolver';
import { ThemeStore } from './theme.store';

const session_storage_theme_key = 'nh.user.theme';

@Injectable({
  providedIn: 'root',
})
export class LoginThemeResolver extends AbstractThemeResolver {
  constructor(
    private http: HttpClient,
    protected override settings: SettingsService,
    protected override themeStore: ThemeStore
  ) {
    super(themeStore, settings);
  }

  getTheme(): Observable<Theme> {
    const client =
      new URL(window.location.href).searchParams.get('client') ||
      sessionStorage.getItem(session_storage_theme_key) ||
      'nxh';
    sessionStorage.setItem(session_storage_theme_key, client);
    const themingPath = this.settings.getSystemSettings().theming.path;
    const url = `${themingPath}/${client}/data.json`;
    return this.http.get<Theme>(url);
  }
}
