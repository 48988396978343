import { Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-icon-loader',
  templateUrl: './icon-loader.component.html',
  styleUrls: ['./icon-loader.component.scss'],
})
export class IconLoaderComponent {
  @Input() type = 'spin';

  constructor() {}
}
