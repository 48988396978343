import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

export type CustomDatePipeFormats =
  | 'shortDate'
  | 'longDate'
  | 'monthDate'
  | 'shortTime'
  | 'mediumTime'
  | 'longTime'
  | 'shortDateTime'
  | 'longDateTime'
  | 'shortWeekdayDate'
  | 'shortWeekdayDateTime'
  | 'longWeekdayDate'
  | 'longWeekdayDateTime';

const custom_formats: { [key in CustomDatePipeFormats]?: string | { [lang: string]: string } } = {
  shortDate: { nl: 'dd/MM/yyyy' }, // overwritten because by default NL format is d/M/yyyy
  monthDate: { nl: 'MMMM yyyy', en: 'MMMM, yyyy' },
  shortDateTime: { nl: 'dd/MM/yyyy, HH:mm', en: 'M/d/yy, h:mm aaa' },
  longDateTime: { nl: 'dd MMMM yyyy, HH:mm', en: 'MMMM dd, yyyy, h:mm aaa' },
  shortWeekdayDate: { nl: 'EE dd/MM/yyyy', en: 'EE, M/d/yy' },
  shortWeekdayDateTime: { nl: 'EE dd/MM/yyyy, HH:mm', en: 'EE, M/d/yy, h:mm aaa' },
  longWeekdayDate: { nl: 'EEEE dd MMMM yyyy', en: 'EEEE, MMMM dd, yyyy' },
  longWeekdayDateTime: { nl: 'EEEE dd MMMM yyyy, HH:mm', en: 'EEEE, MMMM dd, yyyy, h:mm aaa' },
};

export const FAR_AWAY_AS_STRING = '9999-12-31T23:59:59Z';
export const FAR_AWAY = new Date(FAR_AWAY_AS_STRING);

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private locale = inject(LOCALE_ID);

  formatDate(
    value: Date | string | number | undefined | null,
    format: CustomDatePipeFormats | string = 'shortDate',
    timezone?: string,
    locale = this.locale
  ) {
    if (!value) {
      return '';
    }
    const customFormat = getCustomFormat(format, locale);
    const result = formatDate(value, customFormat, locale, timezone);

    if (format === 'shortWeekdayDateTime') {
      return capitalizeWeekday(result);
    }

    return result;
  }
}

function getCustomFormat(format: CustomDatePipeFormats | string, locale: string) {
  const custom = custom_formats[format];
  if (custom) {
    if (typeof custom === 'string') {
      return custom;
    } else {
      const lang = locale.startsWith('nl') || locale.startsWith('fr') || locale.startsWith('de') ? 'nl' : 'en';
      return custom[lang] || format;
    }
  } else {
    return format;
  }
}

function capitalizeWeekday(result: string) {
  const index = result.search(/\d/);
  return result.slice(0, index).toUpperCase() + result.slice(index);
}

export function isFarAway(date: string | Date) {
  return new Date(date).getTime() === FAR_AWAY.getTime();
}
