import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ErrorKind } from '@nexuzhealth/shared/domain';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Router } from '@angular/router';
import { AuthMessage, AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'nxh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private readonly loginBtnConfig = new Map<IdentityProviderType, LoginButton>()
    .set(IdentityProviderType.GOOGLE, {
      titleTranslationKey: '_login._provider.with-google',
      logoUrls: ['/assets/icons/identity-providers/google_logo.svg'],
      type: IdentityProviderType.GOOGLE,
      supported: true,
      attr: 'google-login-btn',
    })
    .set(IdentityProviderType.UZL, {
      titleTranslationKey: '_login._provider.with-uzl',
      logoUrls: ['/assets/icons/identity-providers/uzl_logo.svg'],
      type: IdentityProviderType.UZL,
      supported: true,
    })
    .set(IdentityProviderType.EID, {
      titleTranslationKey: '_login._provider.with-eid',
      logoUrls: ['/assets/icons/identity-providers/eid_logo.svg', '/assets/icons/identity-providers/Itsme_logo.svg'],
      type: IdentityProviderType.EID,
      supported: true,
    })
    .set(IdentityProviderType.NEXUZHEALTH_PRO, {
      titleTranslationKey: '_login._provider.with-nexuzhealth',
      logoUrls: ['/assets/icons/identity-providers/nexuzhealth-logo.svg'],
      type: IdentityProviderType.NEXUZHEALTH_PRO,
      supported: true,
      attr: 'nxh-login-btn',
    });
  loginBtns = [];

  @Input() message: AuthMessage | null;

  constructor(private auth: AuthService, private router: Router, private settings: SettingsService) {}

  ngOnInit(): void {
    if (!this.auth.targetApp) {
      this.router.navigate(['/', 'product-selector']);
    }
    if (Array.isArray(this.settings.custom?.enabledProviders)) {
      this.loginBtns = this.settings.custom?.enabledProviders
        .map((provider) => this.loginBtnConfig.get(provider))
        .filter((provider) => !!provider);
    }
  }

  login(idpType: IdentityProviderType) {
    switch (idpType) {
      case IdentityProviderType.GOOGLE:
        this.auth.login('/', 'google-oauth2');
        break;
      case IdentityProviderType.UZL:
        this.auth.login('/', 'ADFSUZLeuven');
        break;
      case IdentityProviderType.EID:
        this.auth.login('/', 'CSAM');
        break;
      case IdentityProviderType.NEXUZHEALTH_PRO:
        this.auth.login('/', 'Username-Password-Authentication');
        break;
      default:
        break;
    }
  }

  getMessageTitle(message: string): string {
    switch (message) {
      case ErrorKind.UNAUTHENTICATED:
        return 'auth:_login._errors.no-account';
      case 'session_expired':
        return 'auth:_login._info.session_expired';
      default:
        return 'auth:_login._errors.service-unavailable';
    }
  }
  getMessageBody(message: string): string {
    switch (message) {
      case ErrorKind.UNAUTHENTICATED:
        return 'auth:_login._errors.try-again-helpdesk';
      case 'session_expired':
        return 'auth:_login._info.login-to-continue';
      default:
        return 'auth:_login._errors.login-again-helpdesk';
    }
  }
}

interface LoginButton {
  titleTranslationKey: string;
  logoUrls: string[];
  type: IdentityProviderType;
  supported?: boolean;
  attr?: string;
}

enum IdentityProviderType {
  GOOGLE = 'GOOGLE',
  UZL = 'UZL',
  EID = 'EID',
  NEXUZHEALTH_PRO = 'NEXUZHEALTH_PRO',
}
