<svg
  class="icon"
  [ngClass]="{ 'spin-loader': type === 'spin', 'pulse-loader': type === 'pulse' }"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 159 159"
>
  <defs>
    <path
      d="M94.02 97.68C93.98 97.72 93.95 97.77 93.91 97.79C93.9 97.8 93.81 97.85 93.79 97.86C93.32 98.2 92.82 98.52 92.3 98.81C91.05 99.53 84.78 103.13 73.5 109.62L73.45 109.64L73.38 109.69L45.25 125.86C45.24 125.86 45.23 125.86 45.23 125.86C34.83 131.8 21.58 128.2 15.63 117.8C9.7 107.43 13.27 94.21 23.62 88.24C25.51 87.16 34.92 81.75 51.87 72.01L61.37 88.53C61.41 88.59 61.44 88.62 61.44 88.62C61.46 88.69 61.49 88.75 61.53 88.81C61.61 88.94 62 89.62 62.71 90.84L63.81 92.76C63.81 92.77 63.81 92.78 63.81 92.78C63.81 92.8 63.81 92.8 63.83 92.82C64.33 93.5 64.87 94.15 65.44 94.77C66.31 95.69 67.25 96.55 68.26 97.32C69.29 98.09 70.38 98.77 71.53 99.35C72.11 99.62 72.57 99.84 72.68 99.89C72.98 100.03 73.27 100.14 73.57 100.25C74.06 100.43 74.58 100.61 75.1 100.77C75.51 100.88 75.89 101 76.3 101.09C77.08 101.27 77.87 101.4 78.67 101.49C78.78 101.52 78.92 101.52 79.05 101.54C79.73 101.61 80.43 101.65 81.13 101.65C81.25 101.67 81.37 101.68 81.49 101.67C83.28 101.66 85.06 101.42 86.8 100.97C86.81 100.97 86.83 100.96 86.84 100.95C87.72 100.72 88.59 100.43 89.44 100.09C89.48 100.07 89.52 100.05 89.57 100.05C90 99.87 90.43 99.66 90.86 99.46C91.29 99.26 91.71 99.03 92.12 98.78C92.5 98.56 93.64 97.9 94.02 97.68Z"
      id="e1QCZCTO8d"
    />
    <path
      d="M68.26 62.71C68.2 62.76 68.13 62.8 68.06 62.84C68.03 62.86 68.01 62.86 67.99 62.89C67.78 63.05 67.57 63.22 67.38 63.4C67.22 63.54 67.06 63.68 66.88 63.81C66.86 63.83 66.81 63.86 66.79 63.88C66.48 64.17 66.16 64.47 65.87 64.78C65.55 65.12 65.21 65.46 64.9 65.8C64.17 66.67 63.5 67.59 62.91 68.55C62.74 68.78 62.59 69.02 62.46 69.28C62.02 70.05 61.63 70.85 61.28 71.67C61.26 71.71 61.24 71.78 61.22 71.83C61.15 72.03 61.06 72.26 60.99 72.46C60.9 72.67 60.83 72.89 60.76 73.11C60.76 73.12 60.76 73.13 60.76 73.14C60.6 73.56 60.47 74 60.36 74.45C60.33 74.45 60.33 74.45 60.36 74.47C60.24 74.9 60.13 75.35 60.04 75.8C59.97 76.14 59.91 76.5 59.86 76.86C59.79 77.4 59.73 77.92 59.68 78.44C59.56 80.35 59.67 82.27 60.02 84.15C60.11 84.61 60.22 85.06 60.33 85.51C60.47 85.96 60.61 86.39 60.74 86.82C60.94 87.43 61.19 88.04 61.44 88.62C61.44 88.62 61.41 88.59 61.37 88.53L51.87 72.01C62.08 66.13 67.76 62.86 68.89 62.21C68.93 62.16 68.99 62.13 69.05 62.12C68.78 62.3 68.51 62.5 68.26 62.71Z"
      id="b4AncIERGm"
    />
    <path
      d="M99.98 68.67C93.86 58.65 80.87 55.34 70.7 61.19C70.7 61.19 70.69 61.19 70.68 61.19C69.7 61.75 69.16 62.06 69.05 62.12C68.99 62.13 68.93 62.16 68.89 62.21C67.76 62.86 62.08 66.13 51.87 72.01C42.14 55.07 36.74 45.67 35.66 43.78C29.69 33.42 33.25 20.18 43.61 14.21C43.63 14.2 43.64 14.19 43.65 14.19C43.65 14.18 43.65 14.17 43.65 14.16C54.04 8.21 67.3 11.79 73.27 22.18C74.35 24.05 79.73 33.42 89.41 50.29C95.05 60.09 98.57 66.22 99.98 68.67Z"
      id="b193eilEFJ"
    />
    <path
      d="M111 87.92L100.52 69.61L100.3 69.23L100.16 68.98C100.05 68.79 99.99 68.69 99.98 68.67C93.86 58.65 80.87 55.34 70.7 61.19C70.7 61.19 70.69 61.19 70.68 61.19C69.7 61.75 69.16 62.06 69.05 62.12C69.52 61.8 70.02 61.49 70.52 61.19C70.52 61.19 70.53 61.18 70.54 61.17L89.41 50.29C104.63 41.51 113.08 36.63 114.77 35.66C125.17 29.71 138.42 33.32 144.37 43.71C150.3 54.08 146.73 67.3 136.38 73.27C136.38 73.27 127.92 78.16 111 87.92Z"
      id="a5Vhi0Y2cS"
    />
    <path
      d="M111 87.92L94.11 97.68L93.91 97.79L93.79 97.86L92.15 98.81C92.13 98.81 92.13 98.81 92.12 98.81C91.72 99.03 91.29 99.26 90.86 99.46C91.29 99.26 91.71 99.03 92.12 98.78C92.31 98.67 93.83 97.79 94.02 97.68C94.26 97.54 94.48 97.38 94.7 97.2C94.74 97.18 94.78 97.15 94.81 97.11C95.19 96.82 95.56 96.53 95.89 96.21C95.95 96.16 96.38 95.78 96.44 95.74C96.62 95.57 96.79 95.4 96.96 95.22C97.25 94.92 97.52 94.63 97.79 94.31C97.87 94.22 97.95 94.13 98.04 94.04C98.11 93.96 98.18 93.88 98.24 93.79C98.69 93.21 99.15 92.62 99.57 91.99C99.6 91.99 99.6 91.97 99.62 91.94C99.62 91.94 99.62 91.93 99.62 91.9C99.82 91.61 99.94 91.46 99.96 91.42C100.05 91.27 100.16 91.09 100.25 90.9C100.3 90.85 100.34 90.79 100.37 90.72C100.37 90.7 100.39 90.68 100.39 90.66C100.46 90.56 100.52 90.45 100.57 90.34C100.68 90.16 100.77 89.98 100.86 89.8C100.91 89.69 100.97 89.6 101.02 89.48C101.02 89.48 101.02 89.46 101.02 89.46C101.11 89.26 101.2 89.08 101.29 88.87C101.38 88.65 101.49 88.42 101.58 88.17C101.77 87.75 101.92 87.31 102.06 86.86C102.1 86.77 102.13 86.67 102.15 86.57C102.26 86.23 102.35 85.92 102.46 85.58C102.46 85.57 102.46 85.54 102.46 85.53C102.49 85.49 102.51 85.44 102.51 85.4C102.53 85.31 102.69 84.6 102.71 84.52C102.74 84.4 102.76 84.31 102.78 84.2C102.85 83.86 102.89 83.54 102.94 83.23C102.98 83.08 103.01 82.93 103.01 82.78C103.03 82.7 103.03 82.63 103.03 82.55C103.07 82.19 103.12 81.81 103.14 81.42C103.33 78.63 102.97 75.83 102.08 73.18C101.95 72.73 101.76 72.28 101.58 71.83C101.58 71.82 101.58 71.81 101.58 71.8C101.47 71.58 101.38 71.38 101.29 71.17C101.2 70.95 101.09 70.73 100.97 70.52C100.91 70.4 100.86 70.29 100.79 70.18C100.78 70.15 100.67 69.93 100.66 69.91C100.55 69.68 100.43 69.46 100.3 69.23C100.31 69.26 100.39 69.38 100.52 69.61L111 87.92Z"
      id="a3wWIOR5ip"
    />
    <path
      d="M119.17 145.84C108.79 151.79 95.55 148.21 89.57 137.84C88.49 135.97 83.1 126.58 73.38 109.69C67.23 98.96 63.82 93 63.13 91.81C63.03 91.65 62.93 91.5 62.84 91.33C62.81 91.28 62.56 90.83 62.52 90.77C62.16 90.13 61.83 89.48 61.53 88.8C61.61 88.94 62 89.62 62.71 90.84L63.81 92.76C63.81 92.77 63.81 92.78 63.81 92.78C63.81 92.8 63.81 92.8 63.83 92.82C64.33 93.5 64.87 94.15 65.44 94.77C66.31 95.69 67.25 96.54 68.26 97.32C69.29 98.09 70.38 98.77 71.53 99.35C72.11 99.62 72.57 99.84 72.68 99.89C72.98 100.03 73.27 100.14 73.57 100.25C74.06 100.43 74.58 100.61 75.1 100.77C75.51 100.88 75.89 101 76.3 101.09C77.08 101.27 77.87 101.4 78.67 101.49C78.78 101.52 78.92 101.52 79.05 101.54C79.73 101.61 80.43 101.65 81.13 101.65C81.25 101.67 81.37 101.68 81.49 101.67C83.28 101.66 85.06 101.42 86.8 100.97C86.81 100.97 86.83 100.96 86.84 100.95C87.72 100.72 88.59 100.43 89.44 100.09C89.48 100.07 89.52 100.05 89.57 100.05C90 99.87 90.43 99.66 90.86 99.46C91.29 99.26 91.72 99.03 92.12 98.81C92.13 98.81 92.13 98.81 92.15 98.81L93.79 97.86L93.91 97.79L94.11 97.68L111 87.92C120.71 104.9 126.11 114.33 127.19 116.21C133.15 126.6 129.58 139.86 119.19 145.84C119.19 145.84 119.17 145.84 119.17 145.84Z"
      id="j5fObV5bqw"
    />
    <path
      d="M99.98 68.67C93.86 58.65 80.87 55.34 70.7 61.19C70.7 61.19 70.69 61.19 70.68 61.19C69.7 61.75 69.16 62.06 69.05 62.12C69.52 61.8 70.02 61.49 70.52 61.19C70.52 61.19 70.53 61.18 70.54 61.17L89.41 50.29C95.05 60.09 98.57 66.22 99.98 68.67Z"
      id="a3JIdSAKv"
    />
    <path
      d="M73.39 109.67C66.88 98.34 63.26 92.04 62.53 90.78C56.92 81.04 59.69 68.63 68.9 62.2C69.08 62.1 70.51 61.29 70.68 61.18C80.99 55.28 94.12 58.75 100.17 68.97C100.19 69.01 100.35 69.3 100.37 69.33C106.24 79.7 102.64 92.86 92.31 98.79C89.79 100.24 83.48 103.87 73.39 109.67Z"
      id="bh8EQtfZ"
    />
  </defs>
  <g class="faa5c576-2bcf-4182-9e13-cfd9372d9c7c">
    <g>
      <use xlink:href="#e1QCZCTO8d" opacity="1" fill="#d64837" fill-opacity="1" />
      <g>
        <use
          xlink:href="#e1QCZCTO8d"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#b4AncIERGm" opacity="1" fill="#c43125" fill-opacity="1" />
      <g>
        <use
          xlink:href="#b4AncIERGm"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#b193eilEFJ" opacity="1" fill="#dc7976" fill-opacity="1" />
      <g>
        <use
          xlink:href="#b193eilEFJ"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#a5Vhi0Y2cS" opacity="1" fill="#68c6d9" fill-opacity="1" />
      <g>
        <use
          xlink:href="#a5Vhi0Y2cS"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#a3wWIOR5ip" opacity="1" fill="#3378a7" fill-opacity="1" />
      <g>
        <use
          xlink:href="#a3wWIOR5ip"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#j5fObV5bqw" opacity="1" fill="#4396c3" fill-opacity="1" />
      <g>
        <use
          xlink:href="#j5fObV5bqw"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#a3JIdSAKv" opacity="1" fill="#712774" fill-opacity="1" />
      <g>
        <use
          xlink:href="#a3JIdSAKv"
          opacity="1"
          fill-opacity="0"
          stroke="#000000"
          stroke-width="1"
          stroke-opacity="0"
        />
      </g>
    </g>
    <g>
      <use xlink:href="#bh8EQtfZ" opacity="1" fill="#431e4b" fill-opacity="1" />
      <g>
        <use xlink:href="#bh8EQtfZ" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="1" stroke-opacity="0" />
      </g>
    </g>
  </g>
</svg>
